.super-complex-table {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.super-complex-table-cell {
    width: 75px;
    min-width: 75px;
    min-height: 54px;
    /* display: flex; */
    /* align-items: center; */
    /* justify-items: center; */
    /* text-align: center; */
    /* vertical-align: middle; */
    margin-left: -3px;
}

.super-complex-table-row {
    display: flex;
    flex-direction: row;
    gap: 3px;
}
.super-complex-table-header {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.super-complex-table-header > th {
    font-weight: 400;
    text-align: center;
    vertical-align: bottom;
    align-content: center !important;
    background-color: #a2ceff;
    color: white;
    border: 1px solid #4891cb;
}
.super-complex-table-row > td {
    font-weight: 400;
    text-align: center;
    vertical-align: bottom;
    align-content: center !important;
    /* display: inline; */
    border: 1px #00000010 solid;
}
.super-complex-table-row > td.disabled {
    font-weight: 400;
    text-align: center;
    vertical-align: bottom;
    align-content: center !important;
    background-color: #f5f5f5;
    color: #00000061;
    /* display: inline; */
}
.super-complex-table-row > td.editable {
    background-color: white !important;
    /* align-items: center;
    display: flex;
    justify-items: center; */
}
.super-complex-table-footer {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.super-complex-table-footer > td {
    font-weight: bold;
    text-align: center;
    align-content: center !important;
    background-color: #f5f5f5;
    border: 1px #00000010 solid;
}
