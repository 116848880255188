.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.step-title {
}

[aria-labelledby='select-component'] li:not(:last-child) {
    border-bottom: 1px solid #00000010;
}

#select-component {
    color: #0000008f;
    text-align: center;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (min-width: 2088px) {
    .step15 {
        width: 2088px;
        margin-left: calc((1556px - 80px - 100vw) / 2 + (100vw - 2088px) / 2);
    }
}
#powered-link {
    display: none !important;
}
