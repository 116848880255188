.multiple-input-table {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
}

.multiple-input-table-cell {
    width: 140px;
    min-height: 54px;
}

.multiple-input-table-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.multiple-input-table-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.multiple-input-table-header > th {
    font-weight: 400;
    text-align: center;
    vertical-align: bottom;
    align-content: center !important;
    background-color: #a2ceff;
    color: white;
    border: 1px solid #0000004c;
    padding-right: 5px;
    padding-left: 5px;
}
.multiple-input-table-row > td {
    font-weight: 400;
    text-align: center;
    vertical-align: bottom;
    align-content: center !important;
    border: 1px #00000010 solid;
}
.multiple-input-table-row > td.disabled {
    font-weight: 400;
    text-align: center;
    vertical-align: bottom;
    align-content: center !important;
    background-color: #f5f5f5;
    color: #00000061;
}
.multiple-input-table-row > td.editable {
    background-color: white !important;
}
.multiple-input-table-footer {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.multiple-input-table-footer > td {
    font-weight: bold;
    text-align: center;
    align-content: center !important;
    background-color: #f5f5f5;
    border: 1px #00000010 solid;
}
